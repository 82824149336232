@import "~@unifly-aero/web-components/src/styles/colors.settings";

:root {
    --logo: url('img/logo.png');
    --splash: url('img/ENAIRE_splash.png');
    --logo-banner: url('img/ENAIRE_white.svg');
    --logo-sidebar: url('img/ENAIRE_white.svg');
    --logo-sidebar-collapsed: url('img/ENAIRE_small.svg');
    --logo-copyright: url('img/ENAIRE_blue.svg')
}

$brand-primary: #282C30;
$brand-secondary: #00224C;
$brand-third: #009fda;

$_color: (
    p500: #009fda,
    p600: #009fda,
    p700: #009fda,
    p800: #00224C,
    p900: #00224C,
    n600: #009FDA
);

:root {
    @each $name, $color in $_color {
        --color-#{$name}: #{$color};
        --color-#{$name}-hsl: #{hexToHsl($color)};
    }
}
